<template>
    <div
        class="feedbacks-page card card-padding h-100 business-idea-page"
        :class="{ 'is-show-filter': showFilter }"
        v-if="!filterLoading"
    >

        <PageHeader
            @showFilter="showFilter = true"
            :showFilter="showFilter"
            :search-input-text="$t('Search ideas...')"
            @handleInput="handleSearch"
        >
            <template #filter-badges>
                <BusinessFilterBadges
                    :initialFilters="filterData.filter"
                    :banks="businessBanks"
                    :countries="businessCountries"
                    :productAreas="businessProductAreas"
                    :timePeriods="timePeriods"
                    @setFilterLength="filterLength = $event"
                />
            </template>
        </PageHeader>

        <div class="feedbacks-wrapper">
            <div
                v-if="showFilter"
                class="filter feedbacks-wrapper__filter">
                <p
                    @click="showFilter = false"
                    class="closeFilter">
                    <img width="20" src="/media/buying/icons/close.svg" alt="">
                </p>
                <BusinessIdeaFilter
                    :initialFilters="filterData.filter"
                    :banks="businessBanks"
                    :countries="businessCountries"
                    :productAreas="businessProductAreas"
                    :timePeriods="timePeriods"
                    :filterLength="filterLength"
                    @apply-filters="applyIdeaFilters"
                />
            </div>
            <div class="feedbacks-wrapper__list">
                <template
                    v-if="!loading && ideas.length"
                    v-for="(idea, index) in ideas"
                    :key="index"
                >
                    <BusinessIdeaItem
                        :ideaData="idea"
                        :showFilter="showFilter"
                        class-name="mt-8"
                        @handle-toggle-status="toggleIdeaStatus(idea)"
                    />
                </template>

                <div
                    v-else-if="!loading && !ideas.length"
                    class="h-100 d-flex align-items-center justify-content-center"
                >
                    <RequestIdeaEmptyState
                        :title="$t('No Idea Found')"
                        :text="filterLength
                            ? $t('There are no open ideas to be found. This might have happened due to the filters applied.')
                            : $t('There are no open ideas yet. Please check back later.')
                        "
                        :icon-path="filterLength
                            ? '/media/buying/icons/no-idea-found-filter.png'
                            : '/media/buying/icons/no-idea-found.svg'
                        "
                        :show-button="true"
                        :button-text="$t('Create Idea')"
                        @handleClickButton="$router.push('/business/feedback/request/create')"
                    />
                </div>
                <CustomLoader height="400px" v-else/>
            </div>
        </div>
        <div></div>
    </div>
</template>
<script>
import BusinessIdeaItem from "@/buying-teams/pages/business/ideas/BusinessIdeaItem";
import CustomLoader from "../../../shared-components/utils/CustomLoader";
import store from "../../../../store";
import BusinessIdeaFilter from "@/buying-teams/pages/business/ideas/BusinessIdeaFilter";
import { BusinessIdeaFilterModel } from "@/store/models/filter/BusinessIdeaFilterModel";
import { RequestStatusEnum } from "@/store/enums/request/RequestStatusEnum";
import { RequestStatusModel } from "@/store/models/request/RequestStatusModel";
import BusinessFilterBadges from "@/buying-teams/shared-components/filter/BusinessFilterBadges";
import {setCurrentPageBreadcrumbs, setCurrentPageCounter} from "@/core/helpers/breadcrumb";
import PageHeader from "@/buying-teams/shared-components/feedbacks/PageHeader";
import RequestIdeaEmptyState from "@/buying-teams/shared-components/utils/RequestIdeaEmptyState";
import BusinessFiltersTypeEnum from "@/store/enums/business/BusinessFiltersTypeEnum";

export default {
    name: "BusinessIdeas",

    components: {
        RequestIdeaEmptyState,
        PageHeader,
        BusinessFilterBadges,
        BusinessIdeaFilter,
        BusinessIdeaItem,
        CustomLoader
    },

    data() {
        return {
            filterLoading: true,
            loading: false,
            ideas: [],
            showFilter: false,
            searchTimeoutId: null,
            filterLength: 0,
            filterData: {
                page: 0,
                type: "idea",
                search: "",
                filter: {
                    bank_ids: [],
                    time_period: "*",
                    product_areas: [],
                    countries: [],
                    display_closed_items: false
                }
            }
        };
    },

    computed: {
        businessBanks() {
            return store.getters.businessBanks;
        },
        businessCountries() {
            return store.getters.businessCountries;
        },
        businessProductAreas() {
            return store.getters.businessProductAreas;
        },
        businessIdeaFilter() {
            return store.getters.getBusinessFiltersState.ideas;
        },
        timePeriods() {
            return store.getters.timePeriods;
        },
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.$t('Ideas'),
            subTitle: this.$t('Ideas for your Banks (improvements, developments, other)')
        })
    },
    async mounted() {
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.IDEAS);
        this.filterLoading = false;
        this.setFilters(this.businessIdeaFilter)
        await this.getIdeas(this.filterData);
    },

    methods: {
        async getIdeas(filter) {
            this.ideas.length = 0;
            this.loading = true;
            this.ideas = await store.dispatch("getIdeas", filter)
                .finally(() => {
                    this.loading = false;
                });

            setCurrentPageCounter({counter: this.ideas.length})

        },
        async applyIdeaFilters(filters) {
            this.setFilters(new BusinessIdeaFilterModel(filters));
            await this.getIdeas(this.filterData);
        },
        setFilters(filters) {
            this.filterData.filter = filters;
        },
        async toggleIdeaStatus(idea) {
            let status   = idea.status.code === RequestStatusEnum.OPEN ? RequestStatusEnum.CLOSED : RequestStatusEnum.OPEN
            const params = {
                id: idea.id,
                status: status
            };
            await store.dispatch('toggleIdeaStatus', params)
                .then(res => {
                    if (res) {
                        idea.status = new RequestStatusModel(status);
                    }
                })
        },
        async handleSearch(event) {
            let search = event.target.value

            if (search !== this.filterData.search && this.searchTimeoutId) clearTimeout(this.searchTimeoutId)
            this.filterData.search = search;

            this.searchTimeoutId = setTimeout(async () => {
                await this.getIdeas(this.filterData);
            }, 500);
        }
    }
};
</script>
<style lang="scss">
.business-idea-page {
    @media (max-width: 768px) {
        .page-header {
            flex-wrap: nowrap !important;
            padding-top: 20px;

            > .w-100 {
                width: max-content !important;
                margin-top: 0 !important;
            }
        }
    }
}
</style>
