<template>
  <div class="demo-filterForm">
    <el-form :model="filterForm" ref="businessIdaFilter" :rules="businessIdeaFilterFormRules">
      <el-form-item class="me-6" prop="time_period">
        <label class="d-block" for="">{{ $t("Time Period") }}</label>
        <el-select v-model="filterForm.time_period" :placeholder="$t('Time Period')">
          <el-option
            v-for="(item,index) in timePeriods"
            :key="index"
            :label="item.title"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="me-6 mb-10" prop="product_areas">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogProductAreaVisible = true" class="select-popup"></div>

          <label class="d-block" for="">{{ $t("Product Area") }}</label>
          <el-select
            v-model="filterForm.product_areas"
            multiple
            collapse-tags
            disabled
            :placeholder="$t('Select Product Area')">

            <el-option
              v-for="(item, index) in productAreas"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item class="me-6 mb-10" prop="bank_ids">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogBanksVisible = true" class="select-popup"></div>

          <label class="d-block">{{ $t("Banks") }}</label>
          <el-select
            v-model="filterForm.bank_ids"
            multiple
            disabled
            collapse-tags
            :placeholder="$t('Select Banks')"
          >
            <el-option
              v-for="(item,index) in banks"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
              {{ item.name }}
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item class="me-6 mb-14" prop="countries">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogCountriesVisible = true" class="select-popup"></div>

          <label class="d-block mt-5" for="">{{ $t("Country of the Bank") }}</label>
          <el-select
            v-model="filterForm.countries"
            multiple
            disabled
            collapse-tags
            :placeholder="$t('Select Countries')">
            <el-option
              v-for="(item, index) in countries"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item class="switch" :label=" $t('Show closed ideas')" prop="display_closed_items">
        <el-switch v-model="filterForm.display_closed_items"></el-switch>
      </el-form-item>

    </el-form>

    <div class="filters-counts mt-0 mb-6 me-6" v-show="filterLength">
      <span>
        <div class="filter-badges">{{ filterLength }}</div>
        {{ $t('Filters Selected') }}
      </span>
      <button @click="clearAll">{{ $t('Clear All') }}</button>
    </div>

    <button class="main-btn-outline filter-btn" @click="applyFilters">{{ $t("Apply Filters") }}</button>
  </div>

  <SelectModal
    v-if="dialogBanksVisible"
    :popupVisible="dialogBanksVisible"
    :dataForSelect="banks"
    :dataForUpdate="filterForm.bank_ids"
    :multiple="true"
    :title="$t('Select Banks')"
    :subtitle="$t('Select relevant banks')"
    :placeholder="$t('Search Banks')"
    @handle-close="dialogBanksVisible = false"
    @handle-confirm="handleBanksSelect"
  />

  <SelectModal
    v-if="dialogProductAreaVisible"
    :title="$t('Select Product Areas')"
    :subtitle="$t('Select Product Areas you want filter on')"
    :placeholder="$t('Search Product Areas')"
    :popupVisible="dialogProductAreaVisible"
    :dataForSelect="productAreas"
    :dataForUpdate="filterForm.product_areas"
    :multiple="true"
    :selfValue="true"
    @handle-close="dialogProductAreaVisible = false"
    @handle-confirm="handleConfirmProductAreas"
  />

  <SelectModal
    v-if="dialogCountriesVisible"
    :title="$t('Select Country')"
    :subtitle="$t('Select Country you want to filter on')"
    :placeholder="$t('Search Country')"
    :popupVisible="dialogCountriesVisible"
    :dataForSelect="countries"
    :dataForUpdate="filterForm.countries"
    :multiple="true"
    :selfValue="true"
    @handle-close="dialogCountriesVisible = false"
    @handle-confirm="handleConfirmCountries"
  />
</template>

<script>
  import SelectModal from "@/buying-teams/shared-components/modals/SelectModal";

  export default {
    name: "BusinessIdeaFilter",

    components: {
      SelectModal
    },

    props: {
      banks: Array,
      countries: Array,
      productAreas: Array,
      timePeriods: Array,
      initialFilters: Object,
      filterLength: Number
    },

    emits: [
      "handle-close-filter",
      "apply-filters"
    ],

    created() {
      this.fillFilter(this.initialFilters);
    },

    data() {
      return {
        dialogBanksVisible: false,
        dialogCreatorsVisible: false,
        dialogProductAreaVisible: false,
        dialogCountriesVisible: false,
        filterForm: {
          product_areas: "",
          creator_ids: [],
          bank_ids: [],
          display_closed_items: false
        },
        businessIdeaFilterFormRules: {
          time_period: [
            { required: true, message: this.$t("Please select Time Period"), trigger: "change" },
          ],
          bank_ids: [
            { required: true, message: this.$t("Please select Bank"), trigger: "change" }
          ],
          product_areas: [
            { required: true, message: this.$t("Please select Product Areas"), trigger: "change" }
          ],
          countries: [
            { required: true, message: this.$t("Please select Countries"), trigger: "change" }
          ]
        }
      };
    },

    methods: {
      handleBanksSelect(event) {
        this.dialogFormVisible = false;
        this.filterForm.bank_ids = [ ...event ];
      },
      handleConfirmProductAreas(event) {
        this.filterForm.product_areas = event;
        this.dialogProductAreaVisible = false;
      },
      handleConfirmCountries(event) {
        this.filterForm.countries = event;
        this.dialogCountriesVisible = false;
      },
      handleCreatorsSelect(event) {
        this.dialogFormVisible = false;
      },
      applyFilters() {
        this.$refs.businessIdaFilter.validate(valid => {
          if (valid) {
            this.$emit("apply-filters", this.filterForm);
          }
        })
      },
      closeFilter() {
        this.$emit("handle-close-filter");
      },
      fillFilter(filterData) {
        this.filterForm.bank_ids = filterData.bank_ids;
        this.filterForm.countries = filterData.countries;
        this.filterForm.product_areas = filterData.product_areas;
        this.filterForm.time_period = filterData.time_period;
        this.filterForm.display_closed_items = filterData.display_closed_items;
      },
      clearAll() {
        this.filterForm.bank_ids = this.banks.map(b => b.id);
        this.filterForm.countries = this.countries;
        this.filterForm.product_areas = this.productAreas;
        this.filterForm.time_period = '*';
        this.filterForm.display_closed_items = false;
        this.applyFilters();
      }
    }
  };
</script>
<style lang="scss">

</style>
